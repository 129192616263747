.custom-rc-collapse-item {
    border-top: 0 !important;
    border-bottom: 1px solid #EAE9F2;
}

.custom-rc-collapse-header {
    @apply pt-6 pb-6 !important;
    color: #09131F !important;
    line-height: 22px;
    font-weight: 500;
    font-family: 'Circular Std';
    align-items: baseline !important;
}

.custom-rc-collapse-header:focus {
    outline: none !important;
}

@media screen and (max-width: 767.98px) {
    .custom-rc-collapse-header {
        padding-left: 0 !important;
        @apply pt-4 pb-3 !important;
        padding-right: 0 !important;
    }
}